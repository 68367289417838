import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Thrive',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/2dd8ba48-2a3f-488d-2ea6-53b77789b600/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#b32026',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fffff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fffff',
};
